.shared-result-modal-pane {
  .modal-pane-content {
    width: 90%;
    max-width: 500px;
    min-height: 300px !important;
  }

  .shared-result-pane {
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;

    .message {
      text-align: center;
      padding-top: 20px;
    }

    .result-details {
      padding: 40px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .detail-box {
        border: 1px solid #FF5A5F;
        border-radius: 4px;
        padding: 4px 30px;
        text-align: center;

        .score-value {
          color: #FF5A5F;
          font-weight: 600;
        }
      }
    }

    .footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      box-shadow: 0px -1px 20px 0px #555;
      flex-grow: 1;

      .share-result-button {
        background-color: #FF5A5F;
        color: white;
      }

      .retake-test-button {
        background-color: unset;
        border: 1px solid #FF5A5F;
        color: #FF5A5F;
      }

      .result-modal-btn {
        border-radius: 4px;
        padding: 4px 0px;
        width: 170px;
        margin: 20px 0;
        font-weight: 600;
      }
    }

  }

}