.credentials-bio-view {
    display: flex;
    padding: 0px 20px;
    margin-bottom: 26px;

    .profile-frame {
        flex-grow: 1;
        display: flex;
        align-items: center;

        .profile-picture-icon {
            height: 120px;
            overflow: hidden;
            min-width: 120px;
            max-width: 120px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
            }

            .initials {
                width: 100%;
                height: 100%;
                display: flex;
                color: white;
                font-size: 44px;
                border-radius: 50%;
                align-items: center;
                background: #D8B4C4;
                justify-content: center;
            }
        }

        .profile-details-frame {
            margin-left: 30px;

            .full-name {
                font-size: 24px;
            }

            .specialty {
                font-size: 16px;
                margin-top: 9px;
                line-height: 17px;
            }

            .specialty.empty {
                color: grey;
                font-style: italic;
            }

            .bio {
                margin-top: 8px;
                font-size: 12px;
                line-height: 130%;
                padding-right: 63.5px;
            }
        }
    }

    .profile-strength-frame {
        position: relative;
        height: 136px;
        flex: 0 0 187px;
        border-left: 1px solid #DBDBDB;

        .profile-strength-label {
            font-size: 12px;
            margin-top: 10px;
            text-align: center;
        }

        .pie-chart-frame {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .VictoryContainer {
                position: absolute !important;
                width: 100%;
                height: 100%;

                svg {
                    position: absolute;
                    top: 12px;
                    width: 100%;
                    height: 100% !important;
                }
            }

            .profile-strength-label {
                position: relative;
                top: 5px;
                font-size: 24px;
            }
        }
    }

}




// mobile
.credentials-bio-mobile-view {
    margin-bottom: 15px;

    .profile-strength-frame {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 0px #DBDBDB;

        .profile-strength-label {
            font-size: 13px;
        }

        .profile-strength-label.black {
            margin-right: 4px;
        }
    }

    .profile-frame {
        display: flex;
        align-items: center;
        flex-direction: column;

        .profile-picture-icon {
            width: 90px;
            height: 90px;
            margin-top: 17px;
            overflow: hidden;
            border-radius: 50%;

            .initials {
                width: 100%;
                height: 100%;
                display: flex;
                color: white;
                font-size: 32px;
                border-radius: 50%;
                align-items: center;
                background: #D8B4C4;
                justify-content: center;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .full-name {
            font-size: 22px;
            margin-top: 18px;
        }

        .specialty {
            font-size: 15px;
            margin-top: 10px;
            line-height: 17px;
        }

        .specialty.empty {
            color: grey;
            font-style: italic;
        }

        .bio {
            width: 92%;
            margin-top: 9px;
            font-size: 13px;
            line-height: 130%;
            text-align: center;
        }
    }
}