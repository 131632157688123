$bottom: 275%;

.modal-pane {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;

    .background {
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.3);
        background: rgba(42, 42, 42, 0.4);
    }

    .modal-pane-content {
        background-color: white;
        border-radius: 4px;
        min-width: 450px;
        min-height: 350px;
        transition: 0.3s;

        .modal-header {
            margin-top: 30px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        .modal-message {
            font-size: 18px;
            text-align: center;
        }

        .modal-button-frame {
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: 30px;
            display: flex;
            justify-content: space-between;

            .modal-button {
                font-size: 12px;
                font-weight: bold;
                padding: 8px 15px;
                border-radius: 4px;
            }

            .modal-button.bg-una {
                color: white;
            }
        }
    }

    .modal-pane-content.mobile {
        width: 90%;
        min-width: 0px;
        max-width: 450px;
    }

    .slide-down {
        transform: translate(-50%, $bottom);
        transform: translate3d(-50%, $bottom, $bottom);
        -webkit-transform: translate(-50%, $bottom);
        -moz-transform: translate(-50%, $bottom);
        -ms-transform: translate(-50%, $bottom);
        -o-transform: translate(-50%, $bottom);
    }
}


/* ----- ANIMATIONS ----- */
.example-enter {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, $bottom);
    transform: translate3d(-50%, $bottom, 0);
    -webkit-transform: translate(-50%, $bottom);
    -moz-transform: translate(-50%, $bottom);
    -ms-transform: translate(-50%, $bottom);
    -o-transform: translate(-50%, $bottom);
}

.example-enter.example-enter-active {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transition: opacity 1s ease;
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}

.example-exit {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    -webkit-transform: translate(-50%, 0, 0);
    -moz-transform: translate(-50%, 0, 0);
    -ms-transform: translate(-50%, 0, 0);
    -o-transform: translate(-50%, 0, 0);
    transition: opacity 1s ease;
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}

.example-exit.example-exit-active {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, $bottom);
    transform: translate3d(-50%, $bottom, 0);
    -webkit-transform: translate(-50%, $bottom);
    -moz-transform: translate(-50%, $bottom);
    -ms-transform: translate(-50%, $bottom);
    -o-transform: translate(-50%, $bottom);
}
