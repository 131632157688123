.progress-bar-view {
    height: 24px;
    display: flex;

    .progress-block {
        width: 24px;
        height: 100%;
        margin-right: 1px;
        background: #F2F2F2;

        .fill {
            height: 100%;
            transition: 0.075s;
        }

        // transition delays
        .second {
            transition-delay: 0.075s;
        }
    
        .third {
            transition-delay: 0.15s;
        }
    
        .fourth {
            margin-right: 0px;
            transition-delay: 0.225s;
        }

        // fill backgrounds
        .blue {
            background: #24A4DB;
        }

        .green {
            background: #3EC253;
        }

        .orange {
            background: #EB903D;
        }

        .una {
            background: #FF5A5F;
        }
    }
}

.progress-bar-view.mobile {
    height: 16px;
    
    .progress-block {
        width: 16px;
        height: 16px;
    }
}