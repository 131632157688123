.roles-view-pane {
    .modal-pane-content {
        max-width: 620px;
        width: 95% !important;
        min-height: 0px !important;

        .roles-frame {
            width: 100% !important;

            .role-label.aqua {
                margin-left: 3px;
            }
        }
    }
}