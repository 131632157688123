.reference-mobile-view {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .content-frame::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
    }

    .content-frame {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 50px;
        overflow-y: scroll;
        padding: 40px 20px 0px 20px;

        .una-logo {
            width: 90.5px;
            margin: auto;

            img {
                width: 100%;
            }
        }

        .header-frame {
            margin: 30px 0px 60px 0px;

            .header-label {
                width: 100%;
                line-height: 23px;
                text-align: center;
            }
        }

        .note-label {
            color: grey;
            font-size: 13px;
            font-style: italic;
            text-align: center;
            margin: -30px 0px 30px 0px;
        }

        .version {
            color: grey;
            font-size: 10px;
            font-style: italic;
            margin-bottom: 10px;
            text-align: center;
        }

        .form-frame {
            margin-bottom: 40px;

            .form-header-label {
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 32px;
            }

            .form-input-frame {
                margin-bottom: 19px;

                .form-input-header {
                    font-size: 12px;
                    color: #484848;
                    margin-bottom: 4px;
                }

                .form-input-header.red {
                    color: #FF5A5F;
                }

                .form-input {
                    width: 100%;
                    height: 32px;
                    outline: none;
                    font-size: 12px;
                    padding: 9px 10px;
                    border-radius: 4px;
                    border: 1px solid #DBDBDB;
                    box-sizing: border-box;
                }

                .form-text-input {
                    width: 100%;
                    resize: none;
                    height: 120px;
                    outline: none;
                    font-size: 12px;
                    padding: 9px 3.5%;
                    border-radius: 4px;
                    background: #FAFAFA;
                    border: 1px solid #DBDBDB;
                    box-sizing: border-box;
                }

                .form-input:placeholder-shown,
                .form-text-input:placeholder-shown {
                    color: #919191;
                    background: #FAFAFA;
                }

                .form-input:focus,
                .form-text-input:focus {
                    border-color: #2684FF;
                    box-shadow: 0 0 0 1px #2684FF;
                }

                .form-date-frame {
                    height: 32px;
                }
            }
        }
    }

    .button-frame {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 50px;
        z-index: 1000;
        border-top: 1px solid #DBDBDB;

        .submit-reference-button {
            width: 87.5%;
            height: 32px;
            border: none;
            color: white;
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            border-radius: 4px;
            background: #FF5A5F;
            margin: 9px 6.25% 0px 6.25%;
        }

        .submit-reference-button.hint {
            background: #919191;
            pointer-events: none;
        }
    }
}