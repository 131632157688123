.specialty-read-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        flex: 0 0 48px;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button,
        .save-button {
            position: relative;
            z-index: 2;
            height: 32px;
            font-size: 12px;
            padding: 8px 15px;
            line-height: 15px;
            font-weight: bold;
            border-radius: 4px;
        }

        .save-button {
            color: white;
            margin: 0px 0px 0px 10px;
        }

        .bg-disabled {
            cursor: not-allowed;
        }

        .page-title {
            position: absolute;
            left: 0px;
            right: 0px;
            text-align: center;
        }

        .button-frame {
            display: flex;
        }

        .mobile-button {
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .mobile-button.delete {
            margin-right: 8px;

            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }

        .mobile-button.edit {
            .mobile-button-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .content-frame {
        flex: 1 0;

        .document-frame {
            display: flex;
            width: 90%;
            flex-wrap: wrap;
            max-width: 960px;
            margin: 30px auto 0px auto;
            justify-content: space-between;

            .read-value-frame {
                width: 30%;
                margin-bottom: 42px;

                .value-label {
                    font-size: 12px;
                    line-height: 160%;
                    margin-bottom: 4px;
                }
            }
        }

        .document-frame.mobile {
            width: 100%;
            margin: 0px 0px 0px 10px;

            .read-value-frame {
                width: 100%;
                padding: 7px 11px;
                margin-bottom: 0px;
                box-shadow: 0px 1px 0px #DBDBDB;

                .value-label {
                    font-size: 13px;
                    line-height: 130%;
                }
            }

            .read-value-frame.empty {
                display: none;
            }
        }
    }

    .specialty-modal-pane {
        .modal-pane-content {
            min-width: 350px;
            min-height: 250px;

            .modal-header {
                width: 80%;
                margin: 20px auto;
            }

            .modal-button-frame {
                left: 20px;
                right: 20px;
                bottom: 20px;
            }
        }
    }
}