.course-chapter-view {
    .toolbar {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 48px;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button,
        .save-button {
            height: 32px;
            color: black;
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
            padding: 9px 10px;
            border-radius: 4px;
            background: #F2F2F2;
        }

        .mobile-back-button {
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            background: #F2F2F2;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .save-button {
            color: white;
            background: #FF5A5F;
        }

        .page-header {
            position: absolute;
            left: 50px;
            right: 50px;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
        }
    }

    .toolbar.mobile {
        .page-header-frame {
            position: absolute;
            left: 45px;
            right: 45px;
            display: flex;
            flex-direction: column;

            .page-header {
                position: relative;
                left: 0px;
                right: 0px;
                text-align: center;
            }

            .page-subheader {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .content-frame {
        position: absolute;
        top: 48px;
        left: 0px;
        right: 0px;
        bottom: 48px;

        .chapter-content-frame {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            
            .document-frame {
                width: 90%;
                margin: auto;
                max-width: 960px;

                .empty-chapter-label {
                    color: grey;
                    font-size: 16px;
                    margin-top: 15px;
                    font-style: italic;
                }
            }

            .document-frame.mobile {
                width: 95%;
            }
        }

        .button-frame {
            position: fixed;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 48px;
            display: flex;
            padding: 0px 10px;
            align-items: center;
            box-sizing: border-box;
            justify-content: flex-end;
            box-shadow: 0px -1px 0px #DBDBDB;

            .action-button {
                color: white;
                font-size: 12px;
                font-weight: 600;
                padding: 9px 14px;
                border-radius: 4px;
            }
        }

        .button-frame.mobile {
            justify-content: center;
            padding: 5px;
    
            .action-button {
                width: 100%;
                height: 40px;
            }
        }
    }
}