.test-runner-view {
    .toolbar {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 48px;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button {
            position: absolute;
            top: 8px;
            left: 10px;
            z-index: 1;
            height: 32px;
            display: flex;
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
            padding: 9px 10px;
            border-radius: 4px;
            align-items: center;
            background: #F2F2F2;
            justify-content: center;
        }

        .mobile-back-button {
            position: absolute;
            top: 8px;
            left: 10px;
            z-index: 1;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            background: #F2F2F2;
            justify-content: center;

            .mobile-button-icon {
                width: 16px;
                height: 16px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .page-header {
            position: absolute;
            top: 14px;
            left: 50px;
            right: 50px;
            z-index: 0;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }

        .page-header-frame {
            position: absolute;
            top: 5.5px;
            left: 45px;
            right: 45px;
            // bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .page-header {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                font-size: 15px;
                text-align: center;
            }

            .page-subheader {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .content-frame {
        position: absolute;
        top: 48px;
        left: 0px;
        right: 0px;
        bottom: 48px;
        display: flex;
        flex-direction: column;

        .question-title-frame {
            display: flex;
            padding: 10px 0px;
            justify-content: center;
            box-shadow: 0px 1px 0px #DBDBDB;

            .question-title {
                width: 90%;
                font-size: 24px;
                font-weight: 600;
                max-width: 960px;
                line-height: 130%;
                text-align: left;

                p {
                    margin: 0px;
                }
            }

            .question-title.center {

                text-align: center;
            }
        }

        .answers-frame {
            // flex: 1 0 182px;
            flex: 1 1;
            padding: 10px 0px;

            .answers-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .answer-cell {
                    width: 90%;
                    margin: auto;
                    display: flex;
                    cursor: pointer;
                    max-width: 960px;
                    margin-bottom: 18px;
                    align-items: center;

                    .checkmark-icon {
                        flex-grow: 0;
                        flex-shrink: 0;
                        flex-basis: 32px;
                        // width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        box-sizing: border-box;
                        border: 1px solid #008489;
                    }

                    .answer-label {
                        font-size: 16px;
                        line-height: 20px;
                        margin-left: 20px;
                    }
                }

                .answer-cell.selected {
                    .checkmark-icon {
                        display: flex;
                        align-items: center;
                        background: #008489;
                        justify-content: center;
                    }
                }

                .answer-cell:last-child {
                    margin: 0px auto;
                }
            }
        }

        .timer-frame {
            display: flex;
            flex: 0 0 71px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 5px 0px 10px 0px;
            box-shadow: 0px -1px 0px #DBDBDB;

            .timer {
                font-size: 24px;
            }
        }

    }

    .content-frame.mobile {
        .question-title-frame {
            .question-title {
                font-size: 20px;
            }
        }

        .timer-frame {
            flex: 0 0 56px;
        }
    }

    .button-frame {
        position: fixed;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 48px;
        display: flex;
        padding: 8px 10px;
        align-items: center;
        background: white;
        box-sizing: border-box;
        justify-content: flex-end;
        box-shadow: 0px -1px 0px #DBDBDB;

        .previous-question-button,
        .next-question-button {
            font-size: 12px;
            font-weight: 600;
            padding: 9px 10px;
            border-radius: 4px;
            background: #F2F2F2;
        }

        .next-question-button {
            color: white;
            background: #FF5A5F;
        }
    }

    .button-frame.mobile {
        box-sizing: initial;
        justify-content: center;
        padding: 8px 8px 2px 8px;

        .next-question-button {
            width: 100%;
            height: 40px;
        }
    }

    .modal-pane {
        .modal-pane-content {
            display: flex;
            align-items: center;
            justify-content: center;
            
            .start-test-modal-pane {
                padding: 5%;
                text-align: center;

                .message {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                }

                .submessage-frame {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 30px 0px 57px 0px;

                    .submessage {
                        margin-bottom: 20px;
                    }
                }

                .modal-button {
                    width: 100%;
                    height: 32px;
                    color: #FFFFFF;
                    font-size: 12px;
                    max-width: 280px;
                    line-height: 15px;
                    text-align: center;
                    border-radius: 4px;
                    background: #FF5A5F;

                }
            }
        }

        .modal-pane-content.mobile {
            min-height: 300px;
            
            .start-test-modal-pane {
                .modal-button {
                    height: 40px;
                }
            }
        }
    }
}