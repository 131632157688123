.reference-complete-view {
    .centered-frame {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);

        .una-logo {
            width: 92.5px;
            margin: 0px auto 30px auto;

            img {
                width: 100%;
            }
        }

        .success-label {
            font-size: 21px;
            color: #484848;
            line-height: 40px;
            text-align: center;
        }
    }
}

//-----MOBILE-----//
.reference-complete-mobile-view {
    .centered-frame {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);

        .una-logo {
            width: 92.5px;
            margin: 0px auto 30px auto;

            img {
                width: 100%;
            }
        }

        .success-label {
            font-size: 16px;
            color: #484848;
            line-height: 30px;
            text-align: center;
        }
    }
}