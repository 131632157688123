.test-start-view {
    .toolbar {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 48px;
        box-shadow: 0px 1px 0px #DBDBDB;
    
        .back-button {
            position: absolute;
            top: 8px;
            left: 10px;
            z-index: 1;
            width: 70px;
            height: 32px;
            display: flex;
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
            border-radius: 4px;
            align-items: center;
            background: #F2F2F2;
            justify-content: center;
        }
    
        .page-header {
            position: absolute;
            top: 14px;
            left: 0px;
            right: 0px;
            z-index: 0;
            padding: 0px 85px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    .secondary-toolbar {
        position: fixed;
        top: 49px;
        left: 0px;
        right: 0px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 0px #DBDBDB;

        .secondary-button {
            width: 140px;
            height: 100%;
            font-size: 12px;
            color: #2A2A2A;
            background: white;
        }

        .secondary-button.selected {
            font-weight: 600;
            background: #FFF5F6;
            border-bottom: 2px solid #FF5A5F;
        }
    }

    .content-frame {
        position: absolute;
        top: 98px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        .document-frame {
            width: 90%;
            margin: 30px auto;
            max-width: 960px;

            .categories-list-view {
                margin: 0px;
                padding: 0px;
                list-style: none;
                border-radius: 4px;
                border: 1px solid #DBDBDB;

                .category-cell {
                    height: 60px;
                    display: flex;
                    padding: 22px 20px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: space-between;
                    border-bottom: 1px solid #DBDBDB;

                    .category-label {
                        font-style: 16px;
                    }
                }

                .category-cell.complete {
                    background: rgba(62, 194, 83, 0.1);

                    .category-label {
                        color: #3EC253;
                    }
                }

                .category-cell:last-child {
                    border: none;
                }
            }

            .empty-label {
                color: grey;
                font-style: italic;
            }
        }
    }
}