.aqua {
    color: #008489;
}

.black {
    color: #2A2A2A
}

.blue {
    color: #24A4DB;
}

.green {
    color: #4DD865;
}

.orange {
    color: #EB903D;
}

.una, .red {
    color: #FF5A5F
}

.hint {
    color: #919191;
}












// backgrounds
.bg-aqua {
    background: #008489;
}

.bg-black {

}

.bg-grey {
    background: lightgrey;
}

.bg-grey-2 {
    background: #F2F2F2;
}

.bg-placeholder {
    background: #FAFAFA;
}

.bg-una {
    background: #FF5A5F;
}

.bg-disabled {
    background:#919191;
}