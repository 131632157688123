.credentials-document-pane {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;

    .modal-toolbar {
        position: relative;
        width: 100%;
        display: flex;
        flex: 0 0 48px;
        padding: 0px 10px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .modal-toolbar-button, .mobile-modal-toolbar-button {
            position: relative;
            z-index: 2;
            font-size: 12px;
            padding: 7px 16px;
            font-weight: bold;
            border-radius: 4px;
        }

        .mobile-modal-toolbar-button {
            width: 32px;
            height: 32px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .cancel-icon {
                width: 11.67px;
                height: 11.67px;
            }

            .checkmark-icon {
                width: 14.66px;
                height: 11.18px;
            }
        }

        .bg-disabled,
        .bg-aqua {
            color: white;
            cursor: not-allowed;
        }

        .bg-aqua {
            cursor: pointer;
        }

        .modal-toolbar-header {
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 0;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    .documents-list-view {
        flex: 1 0;
        margin: 0px;
        padding: 0px;
        list-style: none;

        .document-cell {
            display: flex;
            padding: 10px;
            align-items: center;
            box-shadow: 0px 1px 0px #DBDBDB;

            .document-title {
                font-size: 12px;
                margin-left: 10px;
                line-height: 15px;
            }
        }
    }
}