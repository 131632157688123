.date-picker {
    display: flex;
    height: 34px;

    .days-menu, .months-menu, .years-menu {
        margin: 0px;
        height: 100%;
        outline: none;
        flex: 0 0 30%;
        appearance: none;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid #DBDBDB;

        select {
            margin: 0px;
            width: 100%;
            font-size: 12px;
            font-weight: bold;
            box-sizing: border-box;
            padding: 6px 16px 6px 10px;
        }

        .menu-icon {
            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    .bg-placeholder {
           color: #919191;
    }

    .years-menu {
        flex-grow: 1;
    }

    .middle {
        margin: 0px 1%;
    }
}