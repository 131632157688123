.specialty-edit-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        flex: 0 0 48px;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button,
        .save-button {
            position: relative;
            z-index: 2;
            height: 32px;
            font-size: 12px;
            padding: 8px 15px;
            line-height: 15px;
            font-weight: bold;
            border-radius: 4px;
        }

        .save-button {
            color: white;
            margin: 0px 0px 0px 10px;
        }

        .bg-disabled {
            cursor: not-allowed;
        }

        .page-title {
            position: absolute;
            left: 0px;
            right: 0px;
            text-align: center;
        }

        .mobile-button {
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .mobile-button.save {
            .mobile-button-icon {
                width: 18px;
                height: 16px;

                img {
                    width: 17.59px;
                    height: 13.41px;
                }
            }
        }
    }

    .content-frame {
        flex: 1 0;

        .document-frame {
            display: flex;
            width: 90%;
            flex-wrap: wrap;
            max-width: 960px;
            margin: 30px auto 0px auto;
            justify-content: space-between;

            .key-value-frame {
                width: 30%;
                margin-bottom: 42px;

                .value-label {
                    font-size: 12px;
                    line-height: 160%;
                    margin-bottom: 4px;
                }

                .key-value-menu {
                    margin: 0px;
                    width: 100%;
                    height: 34px;
                    appearance: none;
                    background: none;
                    border-radius: 4px;
                    border: 1px solid #DBDBDB;

                    select {
                        width: 100%;
                        height: 100%;
                        outline: none;
                        font-size: 12px;
                        font-weight: 600;
                        box-sizing: border-box;
                        padding: 6px 24px 6px 10px;
                    }
                }

                .key-value-menu.bg-placeholder,
                .key-value-textarea:placeholder-shown {
                    background: #FAFAFA;

                    select {
                        color: #919191;
                    }
                }

                .key-value-input {
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 9px 10px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    border: 1px solid #DBDBDB;
                }
            }
        }

        .document-frame.mobile {
            width: 88%;
            margin: 12px auto;

            .key-value-frame {
                width: 100%;
                margin-bottom: 12px;

                .value-label {
                    font-size: 13px;
                    line-height: 130%;
                }
            }
        }
    }
}