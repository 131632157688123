.select-menu-frame {
    position: relative;
    border-radius: 4px;
    box-sizing: border-box;

    select {
        position: relative;
        z-index: 1;
        border: none;
        appearance: none;
        background: transparent;
    }

    .menu-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 24px;
            height: 24px;
        }
    }
}

.select-menu-frame.ellipsis {
    select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.select-menu-frame.bg-placeholder {
    select {
        select {
            color: #919191;
        }
    }
}