.sc-ifAKCX {
  position: relative;
  z-index: 10;

  .wrapper {
    .sc-bxivhb {
      input {
        padding-right: 10px;
        overflow: hidden;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: transparent;
      }
    }

    .sc-bwzfXH {
      ul {
        padding-bottom: 5px;

        li {
          padding-right: 15px;
        }
      }
    }
  }
}
