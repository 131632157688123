.full-screen-view {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.layout-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.scroll {
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.capitalize {
    text-transform: capitalize;
}

.bold {
    font-weight: 600;
}

.italic {
    font-style: italic;
}

.hide {
    visibility: hidden;
}

.hidden {
    display: none !important;
}

.ellipsis {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.pointer {
    cursor: pointer;
}

.flex-frame {
    display: flex;
}